.App {
  text-align: center;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
}

.Chat-conversation {
  box-sizing: border-box;
  flex-grow: 1;
  margin: auto;
  max-width: 48rem;
  overflow: auto;
  padding: 1rem 0.5rem;
  position: relative;
  width: 100%;
}

.Chat-loader {
  margin: 1rem auto;
  background: rgba(17, 17, 17, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  display: grid;
  place-content: center;
  border-radius: 1rem;
  width: 3rem;
}

.Prompt-form {
  padding: 1rem 0.5rem;
}

.Prompt-container {
  margin: auto;
  max-width: 48rem;
  padding: 0.5rem;
}

.Prompt-textarea {
  align-items: flex-end;
  background-color: rgba(17, 17, 17, 0.05);
  border-radius: 0.375rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 transparent, 0 0 transparent, 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  position: relative;
}

.Prompt {
  appearance: none;
  background: transparent;
  border: none;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 1.25rem;
  height: 4rem;
  margin: 0;
  outline: none;
  padding: 0;
  resize: none;
  width: 100%;
}

.Prompt-char-count {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  margin-right: 0.25rem;
  margin-top: 0.5rem;
  opacity: 0.6;
  text-align: right;
}

.Prompt-submit-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Prompt-submit {
  appearance: none;
  background: #218aff;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  fill: white;
  padding: 0.5rem 1rem;
  transition: all 0.2s;
}

.Prompt-submit:hover {
  filter: brightness(150%);
}

.Prompt-cancel {
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 0.5rem;
  transition: all 0.2s;
}

.Prompt-cancel:hover {
  opacity: 0.5;
}

.Send {
  width: 1.25rem;
}

.x-icon {
  width: 1.5rem;
  fill: black;
}

.Loader {
  padding: 0.5rem;
}
