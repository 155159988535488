.Message {
  background-color: rgba(17, 17, 17, 0.05);
  border-radius: 1rem;
  font-weight: 500;
  margin-left: auto;
  margin-right: 0.5rem;
  max-width: 66%;
  padding: 0.5rem 1rem;
  text-align: right;
  width: fit-content;
}

.Message.self {
  background: #218aff;
  color: white;
}

.Message.bot {
  text-align: left;
  margin-left: 0.5rem;
  margin-right: auto;
}

.Message-container {
  justify-content: space-between;
  align-items: start;
}

.Message-controls {
  display: flex;
  gap: 0.5rem;
  justify-content: end;
  margin-top: 0.5rem;
}

.Message-user-icon {
  background-color: #368bbd;
  border-radius: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: grid;
  place-items: center;
  font-size: 0.75rem;
  height: 2rem;
  margin: 0.5rem 0 1rem auto;
  text-align: center;
  width: 2rem;
}

.Message-user-icon.bot {
  background-image: url(./bot.png);
  background-size: contain;
  background-color: white;
  margin-left: 0;
  margin-right: auto;
}

.Message-text {
  margin-top: 0.25rem;
}

.Message-line {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0.25rem;
}

.Message-control-button {
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
}

.Message-control-button-icon {
  flex-shrink: 0;
  margin-left: auto;
  width: 1rem;
  transition: all 0.2s;
}

.Message-control-button-icon:hover {
  fill: #368bbd;
}
