.Bot-img-container {
  left: 50%;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-height: 100%;
}

.Bot-img {
  width: 100%;
}

.Bot-img-bubble {
  width: 100%;
  display: none;
}

.type {
  font-family: monospace;
  color: #0000;
  background: linear-gradient(-90deg, #000 5px, #0000 0) 10px 0,
    linear-gradient(#000 0 0) 0 0;
  background-size: calc(var(--n) * 1ch) 200%;
  -webkit-background-clip: padding-box, text;
  background-clip: padding-box, text;
  background-repeat: no-repeat;
  animation: b 0.7s infinite steps(1),
    t calc(var(--n) * 0.1s) steps(var(--n)) forwards;
}

@keyframes t {
  from {
    background-size: 0 200%;
  }
}

@keyframes b {
  50% {
    background-position: 0 -100%, 0 0;
  }
}

@media (min-width: 640px) {
  .Bot-bubble-container {
    position: absolute;
    right: 0;
    top: 0;
    width: 12rem;
    transform: translateX(25%);
  }

  .Bot-img-bubble {
    display: block;
  }

  .type-container {
    position: relative;
    top: -9.25rem;
    width: calc(100% - 4rem);
    left: 1.5rem;
    text-align: left;
  }
}

@media (min-width: 768px) {
  .Bot-bubble-container {
    width: 15rem;
    right: -1rem;
  }

  .type-container {
    top: -11.125rem;
    left: 2rem;
    font-size: 1rem;
  }
}
